//menu scroll
function bindOnScrollEvent() {
    $(document).off("scroll").on("scroll", function () {
      window.innerWidth > 767 && (
        addOrRemoveFillBg(),
        $(window).scrollTop() > 200 ? $(".scroll-top-wrapper").addClass("show") : $(".scroll-top-wrapper").removeClass("show")
      )
    })
  }
  function addOrRemoveFillBg() {
    $(window).scrollTop() > 0 ? (
      $(".navbar.fixed-top").removeClass("bg-trans").addClass("bg-white")
    ) : (
        $(".navbar.fixed-top").removeClass("bg-white").addClass("bg-trans")
      )
  }
  function fillbgForSmallScreens() {
    window.innerWidth < 768 && ($(".navbar.fixed-top").addClass("bg-white").removeClass("bg-trans"))
  }
  function scrollToTop() {
    verticalOffset = "undefined" != typeof verticalOffset ? verticalOffset : 0,
      element = $("body"),
      offset = element.offset(),
      offsetTop = offset.top,
      $("html,body").animate({ scrollTop: offsetTop }, 500, "linear")
  }
    bindOnScrollEvent(),
    fillbgForSmallScreens(),
    $(window).resize(function () {
      bindOnScrollEvent(),
        fillbgForSmallScreens(),
        window.innerWidth > 991 && addOrRemoveFillBg()
    });
/* Hamburgers JQuery */
    var forEach = function (t, o, r) {
    if ("[object Object]" === Object.prototype.toString.call(t)) for (var c in t) Object.prototype.hasOwnProperty.call(t, c) && o.call(r, t[c], c, t);
    else for (var e = 0, l = t.length; l > e; e++)o.call(r, t[e], e, t)
    };
    var hamburgers = document.querySelectorAll(".hamburger");
    if (hamburgers.length > 0) {
    forEach(hamburgers, function (hamburger) {
        hamburger.addEventListener("click", function () {
        this.classList.toggle("is-active");
        }, false);
    });
    }

//add active in menu
$('.nav-item h5').on('click',function(){
    if($(this).parent().hasClass("open")){
      $(this).parent().removeClass("open");
    } else{
      $(".nav-item.open").removeClass("open");
      $(this).parent().addClass("open");
    }
});

//mega close click
$('.mega-services .close-btn').click(function(){
    $('.nav-item').removeClass('open');
});

//dropdown close on outside click
window.onclick = function(event) {
    if (!event.target.matches('.nav-item h5')) {
        var dropdowns = document.getElementsByClassName("nav-item");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains('open')) {
                openDropdown.classList.remove('open');
            }
        }
    }
}

//letsconnect
$("#modal-submit").click(function(){
    $(".thanks-modal").show();
    $(".modal").addClass('black');
    $(".initial-modal").hide();
});



//footer mobile accordion
$(document).ready(function(){
    var windowWidth = $(window).width();
    if(windowWidth >= 576){
       $('.footer-list').removeClass('collapse')
    }
  });